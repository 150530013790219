.main-section-container {
    background: linear-gradient(0deg, $secondary-background-color 0%, $primary-background-color 5%);
    
    .main-section {
        max-width: $tv-width;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 8rem;

        // border: 1px solid #eee; // GUIDE

        .main-section-title {
            margin-bottom: 3rem;
            width: 100%;

            h2 {
                margin: 0rem auto;
                display: flex;
                justify-content: center;
                align-items: center;
                background: linear-gradient(0deg, $secondary-color 25%, $primary-color 60%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: 'Bungee Shade';
                font-size: 3rem;

                &::after,
                &::before {
                    content: '';
                    margin: 1rem;
                    width: 100%;
                    height: 2px;
                    border-radius: 0.6rem;
                    background-color: #eee;
                }
            }
        }

        .main-section-content {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media (max-width: $tv-width) {
            max-width: $desktop-width;

            padding-top: 7rem;
            padding-bottom: 7rem;
        }

        @media (max-width: $desktop-width) {
            max-width: $laptop-width;

            padding-top: 6rem;
            padding-bottom: 6rem;
        }

        @media (max-width: $laptop-width) {
            max-width: $tablet-width;

            padding-top: 5rem;
            padding-bottom: 5rem;
        }

        @media (max-width: $tablet-width) {
            max-width: $mobile-width;

            padding-top: 4rem;
            padding-bottom: 4rem;

            .main-section-title {

                h2 {
                    font-size: 2.5rem;

                    &::after,
                    &::before {
                        content: '';
                        width: 4.2rem;
                        height: 0.25rem;
                    }
                }
            }
        }

        @media (max-width: $mobile-width) {
            max-width: 100%;

            .main-section-title {
                margin-bottom: 1rem;

                h2 {
                    font-size: 1.8rem;

                    &::after,
                    &::before {
                        content: '';
                        width: 4rem;
                        height: 0.2rem;
                    }
                }
            }
        }
    }
}