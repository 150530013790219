#navigation {
    position: fixed;
    padding-top: 1.25rem;
    box-sizing: content-box;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    transition: 0.2s;
    box-shadow: none;

    #nav-items-container {
        display: flex;
        align-items: center;
        height: 4rem;

        #nav-logo {
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding-left: 1rem;
            width: 0rem;
            height: inherit;

            a {
                position: relative;
                font-family: 'Bungee Shade';
                font-size: 2.2rem;
                font-weight: 800;
                color: $secondary-color;
                background: $secondary-color;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                text-decoration: none;

                &::after {
                    position: absolute;
                    content: attr(text-attr);
                    color: $primary-color;
                    background: linear-gradient(180deg, $primary-color 30%, #00000000 70%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    left: 0;
                    height: 100%;
                    overflow: hidden;
                    transition: 1s;
                }

                &:hover {
                    &::after {
                        height: 0%;
                    }
                }

            }
        }

        #nav-menu-links {
            width: 0rem;
            height: inherit;
            flex-grow: 2;

            ul {
                display: flex;
                align-items: center;
                height: inherit;
                list-style-type: none;

                li {
                    flex-grow: 1;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    height: 2.5rem;
                }

                a {
                    color: inherit;
                    font-weight: 600;
                    text-decoration: none;

                    &:after {
                        display: block;
                        content: '';
                        border-bottom: 0.3rem solid $secondary-color;
                        transform: scaleX(0);
                        transition: 0.2s;
                    }

                    &:hover {
                        color: $secondary-color;

                        &:after {
                            padding-bottom: 0.3rem;
                            transform: scaleX(1);
                        }
                    }




                    &:visited {
                        text-decoration: none;
                    }
                }
            }
        }

        #nav-social-links {
            padding-right: 1rem;
            width: 0rem;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            ul {
                display: flex;

                li {
                    list-style: none;

                    a {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $primary-color;
                        text-decoration: none;

                        .social-icon {
                            padding: 0.5rem;
                            display: flex;
                            align-items: center;
                            z-index: 1;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            background-color: lighten($secondary-color, 30%);
                            border-radius: 2rem;
                            width: 0%;
                            height: 0%;
                            transition: 0.3s;
                        }

                        &:hover {

                            &::before {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        #nav-hamburger {
            flex-grow: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0rem;
            width: 0rem;
            height: 100%;

            #hamburger-button {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 1.5rem;
                height: 50%;
                border-radius: 2rem;

                span {
                    background-color: $primary-color;
                    width: 100%;
                    height: 2px;
                    border-radius: 5rem;
                    animation-duration: 0.5s;
                    animation-fill-mode: forwards;
                    animation-timing-function: ease-out;

                    &:nth-child(2) {
                        animation-duration: 0.2s;
                    }
                }


                &:hover {
                    cursor: pointer;
                }

            }

            @keyframes top {
                from {
                    transform: rotate(0deg) translate(0px);
                }

                to {
                    transform: rotate(45deg) translate(0.38rem, 0.38rem);
                }
            }

            @keyframes middle {
                from {
                    opacity: 100%;
                }

                to {
                    opacity: 0%;
                }
            }

            @keyframes bottom {
                from {
                    transform: rotate(0deg) translate(0px);
                }

                to {
                    transform: rotate(-45deg) translate(0.38rem, -0.38rem);
                }
            }
        }
    }

    #nav-dropdown-menu {
        display: none;
        flex-direction: column;
        height: 0rem;
        width: 100%;
        overflow: hidden;
        border-bottom: 0.2rem solid $secondary-color;
        opacity: 0;
        transition: 0.5s;

        #nav-dropdown-menu-list {
            flex-grow: 1;

            ul {
                display: flex;
                flex-direction: column;
                height: 100%;

                li {
                    padding-right: 1rem;
                    height: 100%;
                    border-top: 1px solid #eee;
                    transition: padding-right 0.2s;

                    a {
                        width: 100%;
                        height: inherit;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        color: $primary-color;
                        font-size: 1.2rem;
                        text-decoration: none;
                    }

                    &:hover {
                        padding-right: 2rem;
                        border-right: 0.2rem solid $secondary-color;

                        a {
                            font-weight: 800;
                            color: $secondary-color;
                        }
                    }
                }
            }
        }

        #nav-dropdown-social-links {
            border-top: 1px solid #eee;
            padding: 1rem;

            ul {
                display: flex;
                justify-content: space-evenly;

                li {
                    list-style: none;

                    a {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $primary-color;
                        text-decoration: none;

                        .social-icon {
                            padding: 0.5rem;
                            display: flex;
                            z-index: 1;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            background-color: lighten($secondary-color, 30%);
                            border-radius: 2rem;
                            width: 0%;
                            height: 0%;
                            transition: 0.3s;
                        }

                        &:hover {

                            &::before {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $tv-width) {
        #nav-items-container {

            #nav-menu-links {
                flex-grow: 2.5;

                ul {

                    li {}

                    a {
                        font-size: 0.9rem;

                        &:after {
                            border-bottom: 0.3rem solid $secondary-color;
                        }

                        &:hover {

                            &:after {
                                padding-bottom: 0.3rem;
                            }
                        }
                    }
                }
            }

            #nav-social-links {

                ul {

                    li {

                        a {

                            .social-icon {

                                svg {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $desktop-width) {

        #nav-items-container {

            #nav-menu-links {
                flex-grow: 2.6;

                ul {

                    li {}

                    a {

                        &:after {
                            border-bottom: 0.2rem solid $secondary-color;
                        }

                        &:hover {

                            &:after {
                                padding-bottom: 0.3rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $laptop-width) {
        #nav-items-container {

            #nav-menu-links {
                flex-grow: 2.7;

                ul {

                    li {}

                    a {
                        font-size: 0.8rem;

                        &:after {}

                        &:hover {

                            &:after {
                                padding-bottom: 0.2rem;
                            }
                        }
                    }
                }
            }

            #nav-social-links {

                ul {

                    li {

                        a {

                            .social-icon {

                                svg {
                                    font-size: 1.3rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $tablet-width) {
        #nav-items-container {
            #nav-menu-links {
                flex-grow: 0;
                overflow: hidden;
            }

            #nav-social-links {
                padding: 0rem;
                flex-grow: 0;
                overflow: hidden;
            }

            #nav-hamburger {
                flex-grow: 1;
                padding-right: 1rem;
            }
        }

        #nav-dropdown-menu {
            display: flex;
        }
    }

    @media (max-width: $mobile-width) {}
}