#projects-section {
    display: flex;
    flex-direction: column;

    .project-content {
        padding: 3rem 1rem;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;

        &:nth-child(odd) {
            flex-direction: row-reverse;
        }

        .project-image {
            padding: 0rem 1rem;
            height: 30rem;
            width: 38rem;

            .project-laptop-image {
                width: inherit;
                height: 100%;
                background-size: 110%;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        .project-info {
            padding: 0rem 1rem;
            height: 30rem;
            width: 38rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            color: $primary-color;

            .project-name {
                font-size: 2rem;
            }

            .project-description {}

            .project-tech-stack {
                width: 18.75rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .tech-stack-item {
                    margin: 0.9rem 1.25rem;
                    width: 3.5rem;
                    height: 3.5rem;
                    background-size: 80%;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 0.6rem;
                    box-shadow: 0rem 0rem 0.9rem 0rem #ddd;
                    transition: 0.2s ease-out;

                    .tech-stack-popup {
                        opacity: 0;
                        position: relative;
                        bottom: 1.5rem;
                        white-space: nowrap;
                        display: flex;
                        justify-content: center;
                        font-weight: 600;
                        transition: 1.5s ease-out;
                    }

                    &:hover {
                        transform: scale(1.1);

                        .tech-stack-popup {
                            opacity: 1;
                        }
                    }
                }
            }

            .project-button-prompts {
                width: 100%;
                display: flex;
                justify-content: space-evenly;

                .project-button {
                    @extend %btn;

                    border: 2px solid $primary-color;
                    color: $primary-color;
                    font-weight: 800;
                    white-space: nowrap;

                    &:hover {
                        @include set-bg-color(lighten($primary-color, 15%));
                    }
                }
            }
        }
    }

    @media (max-width: $tv-width) {
        .project-content {

            .project-image {
                width: 30rem;
                height: 25rem;
            }

            .project-info {
                height: 25rem;
            }
        }
    }

    @media (max-width: $desktop-width) {
        .project-content {
            padding-top: 0rem;
            padding-bottom: 6rem;
            flex-wrap: wrap;

            .project-image {
                padding: 0rem;

                .laptop-image {

                    .desktop-image {}
                }
            }

            .project-info {
                padding: 0rem;
                width: 100%;
            }
        }
    }

    @media (max-width: $laptop-width) {
        .project-content {

            .project-image {
                width: 35rem;
                height: 25rem;
            }

            .project-info {
                height: 25rem;
            }
        }
    }

    @media (max-width: $tablet-width) {
        .project-content {

            .project-image {
                width: 100%;
                height: 20rem;
            }

            .project-info {

                .project-tech-stack {

                    .tech-stack-item {

                        .tech-stack-popup {
                            bottom: 1.2rem;
                            font-size: 0.8rem;
                        }
                    }
                }

                .project-button-prompts {

                    .project-button {
                        font-size: 0.8rem;
                        padding: 0.5rem 0.8rem;
                    }
                }
            }
        }
    }

    @media (max-width: $mobile-width) {

        .project-content {
            padding-left: 0rem;
            padding-right: 0rem;

            .project-image {
                height: 15rem;
            }

            .project-info {

                .project-name {
                    font-size: 1.2rem;
                }

                .project-description {}

                .project-tech-stack {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .tech-stack-item {
                        margin: 0.6rem 1rem;
                        width: 3rem;
                        height: 3rem;
                        background-size: 80%;
                        background-position: center;
                        background-repeat: no-repeat;
                        border-radius: 0.6rem;
                        box-shadow: 0rem 0rem 0.9rem 0rem #ddd;
                        transition: 0.2s ease-out;

                        .tech-stack-popup {
                            opacity: 0;
                            position: relative;
                            bottom: 1.5rem;
                            white-space: nowrap;
                            display: flex;
                            justify-content: center;
                            font-weight: 600;
                            transition: 1.5s ease-out;
                        }

                    }
                }

                .project-button-prompts {

                    .project-button {
                        font-size: 0.7rem;
                        padding: 0.3rem 0.6rem;
                    }
                }
            }
        }
    }
}