#skills-section {

    #skills-description {
        text-align: center;
    }

    #skills-categories {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .skills-category {
            margin: 2rem;
            padding: 2rem;
            border-radius: 5rem;
            background-color: $primary-background-color;
            box-shadow: 0rem 0rem 3rem 0rem #ddd;
            border: 0.1rem solid $primary-color;
            background: linear-gradient(0deg, #ddd 0%, #eee 30%);

            h3 {
                text-align: center;
                font-weight: 300;
                font-size: 1.8rem;
            }

            .skills-grid {
                padding: 1.8rem 0rem;
                display: grid;
                grid-template: repeat(1, 1fr) / repeat(3, 1fr);
                column-gap: 0.6rem;
                row-gap: 0.6rem;

                .skill-cell {
                    display: flex;
                    justify-content: center;

                    .skill-chip {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 6rem;
                        height: 6rem;
                        border-radius: 1rem;
                        background-color: transparent;
                        border: 1px solid transparent;
                        transition: 0.5s;

                        .skill-icon {
                            position: absolute;
                            width: inherit;
                            height: inherit;
                            background-size: 60%;
                            background-position: center;
                            background-repeat: no-repeat;
                            transition: 0.5s;
                        }

                        .skill-name {
                            opacity: 0;
                            font-weight: 800;
                            text-align: center;
                            transition: 0.2s;
                        }

                        &:hover {
                            border: 1px solid $primary-color;
                            border-radius: 3rem;
                            @include set-bg-color($primary-color);

                            .skill-icon {
                                opacity: 0;
                            }

                            .skill-name {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    #additional-skills {
        padding-top: 3rem;
        text-align: center;
    }

    @media (max-width: $tv-width) {
        #skills-description {}

        #skills-categories {

            .skills-category {

                h3 {}

                .skills-grid {

                    .skill-cell {

                        .skill-chip {

                            .skill-icon {}

                            .skill-name {}

                            &:hover {

                                .skill-icon {}
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $desktop-width) {}

    @media (max-width: $laptop-width) {}

    @media (max-width: $tablet-width) {}

    @media (max-width: $mobile-width) {

        #skills-description {}

        #skills-categories {

            .skills-category {
                margin: 1rem;
                padding: 1rem;
                border-radius: 3rem;

                h3 {
                    font-size: 1.2rem;
                }

                .skills-grid {
                    padding: 1rem 0rem;
                    column-gap: 0.6rem;
                    row-gap: 0.6rem;

                    .skill-cell {

                        .skill-chip {
                            width: 3rem;
                            height: 3rem;
                            border-radius: 3rem;

                            .skill-icon {}

                            .skill-name {
                                font-size: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}