* {
  margin: 0;
  padding: 0;

  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  caret-color: transparent;
}

html {
  scroll-behavior: smooth;

  body {
    background-color: $primary-background-color;

    #app {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }
  }
}