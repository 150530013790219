$font-stack: 'Montserrat', 'Trebuchet MS', Arial, sans-serif;

$primary-color: #183642;
$secondary-color: #ff5200;

$primary-background-color: #fff;
$secondary-background-color: #f7f7f7;

$text-color-light: #fff;
$text-color-dark: #183642;

$mobile-width: 480px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1200px;
$tv-width: 1536px;