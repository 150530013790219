* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  caret-color: transparent;
}

html {
  scroll-behavior: smooth;
}
html body {
  background-color: #fff;
}
html body #app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  font-family: "Montserrat", "Trebuchet MS", Arial, sans-serif;
  line-height: 1.5;
}

h1 {
  font-family: "Bungee Shade";
}

p {
  color: #666;
  font-size: 1.25rem;
  line-height: 1.75;
}
@media (max-width: 1536px) {
  p {
    font-size: 1.15rem;
  }
}
@media (max-width: 1200px) {
  p {
    font-size: 1.05rem;
  }
}
@media (max-width: 1024px) {
  p {
    font-size: 0.95rem;
  }
}
@media (max-width: 768px) {
  p {
    font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  p {
    font-size: 0.8rem;
  }
}

.main-section-container {
  background: linear-gradient(0deg, #f7f7f7 0%, #fff 5%);
}
.main-section-container .main-section {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 8rem;
}
.main-section-container .main-section .main-section-title {
  margin-bottom: 3rem;
  width: 100%;
}
.main-section-container .main-section .main-section-title h2 {
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #ff5200 25%, #183642 60%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Bungee Shade";
  font-size: 3rem;
}
.main-section-container .main-section .main-section-title h2::after, .main-section-container .main-section .main-section-title h2::before {
  content: "";
  margin: 1rem;
  width: 100%;
  height: 2px;
  border-radius: 0.6rem;
  background-color: #eee;
}
.main-section-container .main-section .main-section-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1536px) {
  .main-section-container .main-section {
    max-width: 1200px;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}
@media (max-width: 1200px) {
  .main-section-container .main-section {
    max-width: 1024px;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
@media (max-width: 1024px) {
  .main-section-container .main-section {
    max-width: 768px;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (max-width: 768px) {
  .main-section-container .main-section {
    max-width: 480px;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .main-section-container .main-section .main-section-title h2 {
    font-size: 2.5rem;
  }
  .main-section-container .main-section .main-section-title h2::after, .main-section-container .main-section .main-section-title h2::before {
    content: "";
    width: 4.2rem;
    height: 0.25rem;
  }
}
@media (max-width: 480px) {
  .main-section-container .main-section {
    max-width: 100%;
  }
  .main-section-container .main-section .main-section-title {
    margin-bottom: 1rem;
  }
  .main-section-container .main-section .main-section-title h2 {
    font-size: 1.8rem;
  }
  .main-section-container .main-section .main-section-title h2::after, .main-section-container .main-section .main-section-title h2::before {
    content: "";
    width: 4rem;
    height: 0.2rem;
  }
}

#navigation {
  position: fixed;
  padding-top: 1.25rem;
  box-sizing: content-box;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  transition: 0.2s;
  box-shadow: none;
}
#navigation #nav-items-container {
  display: flex;
  align-items: center;
  height: 4rem;
}
#navigation #nav-items-container #nav-logo {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  width: 0rem;
  height: inherit;
}
#navigation #nav-items-container #nav-logo a {
  position: relative;
  font-family: "Bungee Shade";
  font-size: 2.2rem;
  font-weight: 800;
  color: #ff5200;
  background: #ff5200;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}
#navigation #nav-items-container #nav-logo a::after {
  position: absolute;
  content: attr(text-attr);
  color: #183642;
  background: linear-gradient(180deg, #183642 30%, rgba(0, 0, 0, 0) 70%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  height: 100%;
  overflow: hidden;
  transition: 1s;
}
#navigation #nav-items-container #nav-logo a:hover::after {
  height: 0%;
}
#navigation #nav-items-container #nav-menu-links {
  width: 0rem;
  height: inherit;
  flex-grow: 2;
}
#navigation #nav-items-container #nav-menu-links ul {
  display: flex;
  align-items: center;
  height: inherit;
  list-style-type: none;
}
#navigation #nav-items-container #nav-menu-links ul li {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 2.5rem;
}
#navigation #nav-items-container #nav-menu-links ul a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
}
#navigation #nav-items-container #nav-menu-links ul a:after {
  display: block;
  content: "";
  border-bottom: 0.3rem solid #ff5200;
  transform: scaleX(0);
  transition: 0.2s;
}
#navigation #nav-items-container #nav-menu-links ul a:hover {
  color: #ff5200;
}
#navigation #nav-items-container #nav-menu-links ul a:hover:after {
  padding-bottom: 0.3rem;
  transform: scaleX(1);
}
#navigation #nav-items-container #nav-menu-links ul a:visited {
  text-decoration: none;
}
#navigation #nav-items-container #nav-social-links {
  padding-right: 1rem;
  width: 0rem;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
#navigation #nav-items-container #nav-social-links ul {
  display: flex;
}
#navigation #nav-items-container #nav-social-links ul li {
  list-style: none;
}
#navigation #nav-items-container #nav-social-links ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #183642;
  text-decoration: none;
}
#navigation #nav-items-container #nav-social-links ul li a .social-icon {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
}
#navigation #nav-items-container #nav-social-links ul li a::before {
  position: absolute;
  content: "";
  background-color: #ffba99;
  border-radius: 2rem;
  width: 0%;
  height: 0%;
  transition: 0.3s;
}
#navigation #nav-items-container #nav-social-links ul li a:hover::before {
  width: 100%;
  height: 100%;
}
#navigation #nav-items-container #nav-hamburger {
  flex-grow: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem;
  width: 0rem;
  height: 100%;
}
#navigation #nav-items-container #nav-hamburger #hamburger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 1.5rem;
  height: 50%;
  border-radius: 2rem;
}
#navigation #nav-items-container #nav-hamburger #hamburger-button span {
  background-color: #183642;
  width: 100%;
  height: 2px;
  border-radius: 5rem;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
#navigation #nav-items-container #nav-hamburger #hamburger-button span:nth-child(2) {
  animation-duration: 0.2s;
}
#navigation #nav-items-container #nav-hamburger #hamburger-button:hover {
  cursor: pointer;
}
@keyframes top {
  from {
    transform: rotate(0deg) translate(0px);
  }
  to {
    transform: rotate(45deg) translate(0.38rem, 0.38rem);
  }
}
@keyframes middle {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}
@keyframes bottom {
  from {
    transform: rotate(0deg) translate(0px);
  }
  to {
    transform: rotate(-45deg) translate(0.38rem, -0.38rem);
  }
}
#navigation #nav-dropdown-menu {
  display: none;
  flex-direction: column;
  height: 0rem;
  width: 100%;
  overflow: hidden;
  border-bottom: 0.2rem solid #ff5200;
  opacity: 0;
  transition: 0.5s;
}
#navigation #nav-dropdown-menu #nav-dropdown-menu-list {
  flex-grow: 1;
}
#navigation #nav-dropdown-menu #nav-dropdown-menu-list ul {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#navigation #nav-dropdown-menu #nav-dropdown-menu-list ul li {
  padding-right: 1rem;
  height: 100%;
  border-top: 1px solid #eee;
  transition: padding-right 0.2s;
}
#navigation #nav-dropdown-menu #nav-dropdown-menu-list ul li a {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #183642;
  font-size: 1.2rem;
  text-decoration: none;
}
#navigation #nav-dropdown-menu #nav-dropdown-menu-list ul li:hover {
  padding-right: 2rem;
  border-right: 0.2rem solid #ff5200;
}
#navigation #nav-dropdown-menu #nav-dropdown-menu-list ul li:hover a {
  font-weight: 800;
  color: #ff5200;
}
#navigation #nav-dropdown-menu #nav-dropdown-social-links {
  border-top: 1px solid #eee;
  padding: 1rem;
}
#navigation #nav-dropdown-menu #nav-dropdown-social-links ul {
  display: flex;
  justify-content: space-evenly;
}
#navigation #nav-dropdown-menu #nav-dropdown-social-links ul li {
  list-style: none;
}
#navigation #nav-dropdown-menu #nav-dropdown-social-links ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #183642;
  text-decoration: none;
}
#navigation #nav-dropdown-menu #nav-dropdown-social-links ul li a .social-icon {
  padding: 0.5rem;
  display: flex;
  z-index: 1;
}
#navigation #nav-dropdown-menu #nav-dropdown-social-links ul li a::before {
  position: absolute;
  content: "";
  background-color: #ffba99;
  border-radius: 2rem;
  width: 0%;
  height: 0%;
  transition: 0.3s;
}
#navigation #nav-dropdown-menu #nav-dropdown-social-links ul li a:hover::before {
  width: 100%;
  height: 100%;
}
@media (max-width: 1536px) {
  #navigation #nav-items-container #nav-menu-links {
    flex-grow: 2.5;
  }
  #navigation #nav-items-container #nav-menu-links ul a {
    font-size: 0.9rem;
  }
  #navigation #nav-items-container #nav-menu-links ul a:after {
    border-bottom: 0.3rem solid #ff5200;
  }
  #navigation #nav-items-container #nav-menu-links ul a:hover:after {
    padding-bottom: 0.3rem;
  }
  #navigation #nav-items-container #nav-social-links ul li a .social-icon svg {
    font-size: 1.4rem;
  }
}
@media (max-width: 1200px) {
  #navigation #nav-items-container #nav-menu-links {
    flex-grow: 2.6;
  }
  #navigation #nav-items-container #nav-menu-links ul a:after {
    border-bottom: 0.2rem solid #ff5200;
  }
  #navigation #nav-items-container #nav-menu-links ul a:hover:after {
    padding-bottom: 0.3rem;
  }
}
@media (max-width: 1024px) {
  #navigation #nav-items-container #nav-menu-links {
    flex-grow: 2.7;
  }
  #navigation #nav-items-container #nav-menu-links ul a {
    font-size: 0.8rem;
  }
  #navigation #nav-items-container #nav-menu-links ul a:hover:after {
    padding-bottom: 0.2rem;
  }
  #navigation #nav-items-container #nav-social-links ul li a .social-icon svg {
    font-size: 1.3rem;
  }
}
@media (max-width: 768px) {
  #navigation #nav-items-container #nav-menu-links {
    flex-grow: 0;
    overflow: hidden;
  }
  #navigation #nav-items-container #nav-social-links {
    padding: 0rem;
    flex-grow: 0;
    overflow: hidden;
  }
  #navigation #nav-items-container #nav-hamburger {
    flex-grow: 1;
    padding-right: 1rem;
  }
  #navigation #nav-dropdown-menu {
    display: flex;
  }
}
#footer {
  min-height: 1.8rem;
  background: url("/public/bg_bggenerator_com4.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
#footer #footer-content {
  margin: auto;
  padding: 0% 10%;
  max-width: 1200px;
}
#footer #footer-content #footer-name {
  padding: 3rem 0rem;
  font-family: "Bungee Shade";
  font-size: 3rem;
  text-align: center;
}
#footer #footer-content #footer-nav {
  margin: auto;
  width: 100%;
}
#footer #footer-content #footer-nav ul {
  width: 100%;
  display: flex;
}
#footer #footer-content #footer-nav ul li {
  flex-grow: 1;
  list-style: none;
  text-align: center;
}
#footer #footer-content #footer-nav ul li a {
  text-decoration: none;
  color: #fff;
  transition: 0.2s;
}
#footer #footer-content #footer-nav ul li:hover {
  cursor: pointer;
}
#footer #footer-content #footer-nav ul li:hover a {
  color: #ff5200;
}
#footer #footer-content #footer-social-links {
  width: 100%;
}
#footer #footer-content #footer-social-links ul {
  padding: 5rem 0rem;
}
#footer #footer-content #footer-social-links ul li {
  list-style: none;
  margin: 2rem 0rem;
}
#footer #footer-content #footer-social-links ul li a {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  text-decoration: none;
  color: #fff;
}
#footer #footer-content #footer-social-links ul li a svg {
  width: 1.6rem;
  height: auto;
  margin-right: 0.5rem;
  transition: 0.3s;
}
#footer #footer-content #footer-social-links ul li a:hover {
  color: #ff5200;
}
#footer #footer-content #footer-social-links ul li a:hover svg {
  margin-right: 1rem;
}
#footer #footer-content #footer-attributions {
  text-align: center;
  font-size: 0.8rem;
}
#footer #footer-content #footer-attributions a {
  font-weight: 800;
  text-decoration: none;
  color: #fff;
}
#footer #footer-content #footer-bottom {
  margin: auto;
  width: 100%;
  border-top: 1px solid #666;
}
#footer #footer-content #footer-bottom span {
  display: block;
  padding: 2rem 0rem;
  text-align: center;
  font-size: 0.7rem;
}
@media (max-width: 768px) {
  #footer #footer-content #footer-name {
    font-size: 2.4rem;
  }
  #footer #footer-content #footer-nav ul li a {
    font-size: 0.8rem;
  }
  #footer #footer-content #footer-social-links ul {
    padding: 3rem 0rem;
  }
}
@media (max-width: 480px) {
  #footer #footer-content #footer-name {
    font-size: 1.9rem;
  }
  #footer #footer-content #footer-nav {
    width: 100%;
  }
  #footer #footer-content #footer-nav ul li a {
    font-size: 0.7rem;
  }
  #footer #footer-content #footer-social-links ul li a {
    font-size: 1rem;
  }
  #footer #footer-content #footer-social-links ul li a svg {
    width: 1.2rem;
  }
}

#projects-section .project-content .project-info .project-button-prompts .project-button {
  display: inline-block;
  border-radius: 0.3rem;
  padding: 0.6rem 1.2rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(0, 0, 0, 0.5);
  text-decoration: none;
  transition: background-color 0.5s;
}
#projects-section .project-content .project-info .project-button-prompts .project-button .button-content {
  display: flex;
  align-items: center;
}
#projects-section .project-content .project-info .project-button-prompts .project-button:hover {
  cursor: pointer;
  box-shadow: none;
  transform: scale(0.98);
}

#home {
  flex-grow: 1;
}

#hero-section-container {
  background: url("/public/bg_bggenerator_com1.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50rem;
  text-align: center;
}
#hero-section #hero-section-content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80%;
  width: 40rem;
  color: #183642;
}
#hero-section #hero-section-content #greeting {
  font-family: "Montserrat", "Trebuchet MS", Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 500;
  color: #183642;
}
#hero-section #hero-section-content #greeting #name {
  font-family: "Bungee Shade";
  font-size: 4rem;
  color: #ff5200;
  background: linear-gradient(0deg, #ff5200 25%, #183642 60%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
#hero-section #hero-section-content #role {
  font-size: 2.5rem;
  font-weight: 500;
  color: #183642;
}
#hero-section #hero-section-content #role #role-title {
  font-size: 3rem;
  font-weight: 900;
  color: #183642;
}
#hero-section #hero-section-content #role #role-title span {
  color: #ff5200;
  font-weight: 900;
}
#hero-section #hero-section-content #intro {
  font-size: 1.5rem;
  font-weight: 500;
  color: #183642;
}
#hero-section #hero-section-content #call-to-action {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
#hero-section #hero-section-content #call-to-action #call-to-action-button {
  position: relative;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff5200;
  text-decoration: none;
  border: 0.3rem solid #ff5200;
  border-radius: 0.3rem;
  backdrop-filter: blur(50px);
}
#hero-section #hero-section-content #call-to-action #call-to-action-button .button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 0.1rem solid #ff5200;
  border-radius: 0.3rem;
  font-size: 1.8rem;
  font-weight: 800;
  text-decoration: none;
  white-space: nowrap;
  z-index: 1;
  transition: 0.5s;
}
#hero-section #hero-section-content #call-to-action #call-to-action-button::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 100%;
  background-color: #fff;
  transition: 1s;
}
#hero-section #hero-section-content #call-to-action #call-to-action-button:hover {
  color: #fff;
  background-color: #ff5200;
}
#hero-section #hero-section-content #call-to-action #call-to-action-button:hover .button-content {
  border-color: #fff;
}
#hero-section #hero-section-content #call-to-action #call-to-action-button:hover::before {
  width: 100%;
  height: 100%;
  background-color: #ff5200;
}
#hero-section #grid {
  display: grid;
  grid-template: repeat(20, 1fr)/repeat(20, 1fr);
  height: 50rem;
  width: 50rem;
  color: #183642;
}
#hero-section #grid:hover {
  cursor: url("/public/paintbrush_color_cursor.png"), pointer;
}
#hero-section #grid .cell {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(128, 128, 128, 0);
  color: rgba(128, 128, 128, 0);
  z-index: 1;
}
#hero-section #grid .cell:nth-child(20n+1):hover ~ #top-layer {
  --positionX: 0;
}
#hero-section #grid .cell:nth-child(n+1):nth-child(-n+20):hover ~ #top-layer {
  --positionY: 0;
}
#hero-section #grid .cell:nth-child(20n+2):hover ~ #top-layer {
  --positionX: 1;
}
#hero-section #grid .cell:nth-child(n+21):nth-child(-n+40):hover ~ #top-layer {
  --positionY: 1;
}
#hero-section #grid .cell:nth-child(20n+3):hover ~ #top-layer {
  --positionX: 2;
}
#hero-section #grid .cell:nth-child(n+41):nth-child(-n+60):hover ~ #top-layer {
  --positionY: 2;
}
#hero-section #grid .cell:nth-child(20n+4):hover ~ #top-layer {
  --positionX: 3;
}
#hero-section #grid .cell:nth-child(n+61):nth-child(-n+80):hover ~ #top-layer {
  --positionY: 3;
}
#hero-section #grid .cell:nth-child(20n+5):hover ~ #top-layer {
  --positionX: 4;
}
#hero-section #grid .cell:nth-child(n+81):nth-child(-n+100):hover ~ #top-layer {
  --positionY: 4;
}
#hero-section #grid .cell:nth-child(20n+6):hover ~ #top-layer {
  --positionX: 5;
}
#hero-section #grid .cell:nth-child(n+101):nth-child(-n+120):hover ~ #top-layer {
  --positionY: 5;
}
#hero-section #grid .cell:nth-child(20n+7):hover ~ #top-layer {
  --positionX: 6;
}
#hero-section #grid .cell:nth-child(n+121):nth-child(-n+140):hover ~ #top-layer {
  --positionY: 6;
}
#hero-section #grid .cell:nth-child(20n+8):hover ~ #top-layer {
  --positionX: 7;
}
#hero-section #grid .cell:nth-child(n+141):nth-child(-n+160):hover ~ #top-layer {
  --positionY: 7;
}
#hero-section #grid .cell:nth-child(20n+9):hover ~ #top-layer {
  --positionX: 8;
}
#hero-section #grid .cell:nth-child(n+161):nth-child(-n+180):hover ~ #top-layer {
  --positionY: 8;
}
#hero-section #grid .cell:nth-child(20n+10):hover ~ #top-layer {
  --positionX: 9;
}
#hero-section #grid .cell:nth-child(n+181):nth-child(-n+200):hover ~ #top-layer {
  --positionY: 9;
}
#hero-section #grid .cell:nth-child(20n+11):hover ~ #top-layer {
  --positionX: 10;
}
#hero-section #grid .cell:nth-child(n+201):nth-child(-n+220):hover ~ #top-layer {
  --positionY: 10;
}
#hero-section #grid .cell:nth-child(20n+12):hover ~ #top-layer {
  --positionX: 11;
}
#hero-section #grid .cell:nth-child(n+221):nth-child(-n+240):hover ~ #top-layer {
  --positionY: 11;
}
#hero-section #grid .cell:nth-child(20n+13):hover ~ #top-layer {
  --positionX: 12;
}
#hero-section #grid .cell:nth-child(n+241):nth-child(-n+260):hover ~ #top-layer {
  --positionY: 12;
}
#hero-section #grid .cell:nth-child(20n+14):hover ~ #top-layer {
  --positionX: 13;
}
#hero-section #grid .cell:nth-child(n+261):nth-child(-n+280):hover ~ #top-layer {
  --positionY: 13;
}
#hero-section #grid .cell:nth-child(20n+15):hover ~ #top-layer {
  --positionX: 14;
}
#hero-section #grid .cell:nth-child(n+281):nth-child(-n+300):hover ~ #top-layer {
  --positionY: 14;
}
#hero-section #grid .cell:nth-child(20n+16):hover ~ #top-layer {
  --positionX: 15;
}
#hero-section #grid .cell:nth-child(n+301):nth-child(-n+320):hover ~ #top-layer {
  --positionY: 15;
}
#hero-section #grid .cell:nth-child(20n+17):hover ~ #top-layer {
  --positionX: 16;
}
#hero-section #grid .cell:nth-child(n+321):nth-child(-n+340):hover ~ #top-layer {
  --positionY: 16;
}
#hero-section #grid .cell:nth-child(20n+18):hover ~ #top-layer {
  --positionX: 17;
}
#hero-section #grid .cell:nth-child(n+341):nth-child(-n+360):hover ~ #top-layer {
  --positionY: 17;
}
#hero-section #grid .cell:nth-child(20n+19):hover ~ #top-layer {
  --positionX: 18;
}
#hero-section #grid .cell:nth-child(n+361):nth-child(-n+380):hover ~ #top-layer {
  --positionY: 18;
}
#hero-section #grid .cell:nth-child(20n+20):hover ~ #top-layer {
  --positionX: 19;
}
#hero-section #grid .cell:nth-child(n+381):nth-child(-n+400):hover ~ #top-layer {
  --positionY: 19;
}
#hero-section #grid #top-layer {
  --positionX: 0;
  --positionY: 0;
  position: absolute;
  width: inherit;
  height: inherit;
  background: url("/public/portrait_painting.png");
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
#hero-section #grid #top-layer #bottom-layer {
  position: absolute;
  background: url("/public/portrait_real.png");
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  height: inherit;
  width: inherit;
  mask-image: url("/public/cursor-mask.svg");
  -webkit-mask-image: url("/public/cursor-mask.svg");
  mask-size: 12.5rem;
  mask-repeat: no-repeat;
  mask-position: calc(var(--positionX) * 5.27%) calc(var(--positionY) * 5.27%);
  transition: all 0.25s ease-out;
}
@media (max-width: 1536px) {
  #hero-section {
    height: 40rem;
  }
  #hero-section #hero-section-content {
    height: 80%;
    width: 35rem;
  }
  #hero-section #hero-section-content #greeting {
    font-size: 2.9rem;
  }
  #hero-section #hero-section-content #intro {
    font-size: 1.3rem;
  }
  #hero-section #hero-section-content #call-to-action {
    padding: 2rem 0rem;
  }
  #hero-section #hero-section-content #call-to-action #call-to-action-button .button-content {
    font-size: 1.6rem;
  }
  #hero-section #grid {
    height: 40rem;
    width: 40rem;
  }
}
@media (max-width: 1200px) {
  #hero-section {
    height: auto;
    flex-direction: column;
  }
  #hero-section #hero-section-content {
    height: 40rem;
    width: 40rem;
    text-align: center;
  }
}
@media (max-width: 768px) {
  #hero-section #hero-section-content {
    height: 40rem;
    width: 100%;
  }
  #hero-section #hero-section-content #greeting {
    font-size: 2rem;
  }
  #hero-section #hero-section-content #greeting #name {
    font-size: 3rem;
  }
  #hero-section #hero-section-content #role {
    font-size: 2rem;
  }
  #hero-section #hero-section-content #role #role-title {
    font-size: 2.4rem;
  }
  #hero-section #hero-section-content #intro {
    font-size: 1.2rem;
  }
  #hero-section #hero-section-content #call-to-action #call-to-action-button .button-content {
    font-size: 1.4rem;
  }
  #hero-section #grid {
    width: 28rem;
    height: 28rem;
  }
  #hero-section #grid #top-layer #bottom-layer {
    mask-size: 8.5rem;
  }
}
@media (max-width: 480px) {
  #hero-section #hero-section-content {
    height: 35rem;
  }
  #hero-section #hero-section-content #greeting {
    font-size: 1.5rem;
  }
  #hero-section #hero-section-content #greeting #name {
    font-size: 2.2rem;
  }
  #hero-section #hero-section-content #role {
    font-size: 1.5rem;
  }
  #hero-section #hero-section-content #role #role-title {
    font-size: 1.9rem;
  }
  #hero-section #hero-section-content #intro {
    font-size: 1rem;
  }
  #hero-section #hero-section-content #call-to-action #call-to-action-button {
    padding: 0.4rem;
  }
  #hero-section #hero-section-content #call-to-action #call-to-action-button .button-content {
    padding: 0.8rem;
    font-size: 1.2rem;
  }
  #hero-section #grid {
    width: 20rem;
    height: 20rem;
  }
  #hero-section #grid #top-layer #bottom-layer {
    mask-size: 6rem;
  }
}

#projects-section {
  display: flex;
  flex-direction: column;
}
#projects-section .project-content {
  padding: 3rem 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
#projects-section .project-content:nth-child(odd) {
  flex-direction: row-reverse;
}
#projects-section .project-content .project-image {
  padding: 0rem 1rem;
  height: 30rem;
  width: 38rem;
}
#projects-section .project-content .project-image .project-laptop-image {
  width: inherit;
  height: 100%;
  background-size: 110%;
  background-position: center center;
  background-repeat: no-repeat;
}
#projects-section .project-content .project-info {
  padding: 0rem 1rem;
  height: 30rem;
  width: 38rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #183642;
}
#projects-section .project-content .project-info .project-name {
  font-size: 2rem;
}
#projects-section .project-content .project-info .project-tech-stack {
  width: 18.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#projects-section .project-content .project-info .project-tech-stack .tech-stack-item {
  margin: 0.9rem 1.25rem;
  width: 3.5rem;
  height: 3.5rem;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.6rem;
  box-shadow: 0rem 0rem 0.9rem 0rem #ddd;
  transition: 0.2s ease-out;
}
#projects-section .project-content .project-info .project-tech-stack .tech-stack-item .tech-stack-popup {
  opacity: 0;
  position: relative;
  bottom: 1.5rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  font-weight: 600;
  transition: 1.5s ease-out;
}
#projects-section .project-content .project-info .project-tech-stack .tech-stack-item:hover {
  transform: scale(1.1);
}
#projects-section .project-content .project-info .project-tech-stack .tech-stack-item:hover .tech-stack-popup {
  opacity: 1;
}
#projects-section .project-content .project-info .project-button-prompts {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
#projects-section .project-content .project-info .project-button-prompts .project-button {
  border: 2px solid #183642;
  color: #183642;
  font-weight: 800;
  white-space: nowrap;
}
#projects-section .project-content .project-info .project-button-prompts .project-button:hover {
  background-color: #2c647a;
  color: #fff;
}
@media (max-width: 1536px) {
  #projects-section .project-content .project-image {
    width: 30rem;
    height: 25rem;
  }
  #projects-section .project-content .project-info {
    height: 25rem;
  }
}
@media (max-width: 1200px) {
  #projects-section .project-content {
    padding-top: 0rem;
    padding-bottom: 6rem;
    flex-wrap: wrap;
  }
  #projects-section .project-content .project-image {
    padding: 0rem;
  }
  #projects-section .project-content .project-info {
    padding: 0rem;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  #projects-section .project-content .project-image {
    width: 35rem;
    height: 25rem;
  }
  #projects-section .project-content .project-info {
    height: 25rem;
  }
}
@media (max-width: 768px) {
  #projects-section .project-content .project-image {
    width: 100%;
    height: 20rem;
  }
  #projects-section .project-content .project-info .project-tech-stack .tech-stack-item .tech-stack-popup {
    bottom: 1.2rem;
    font-size: 0.8rem;
  }
  #projects-section .project-content .project-info .project-button-prompts .project-button {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }
}
@media (max-width: 480px) {
  #projects-section .project-content {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  #projects-section .project-content .project-image {
    height: 15rem;
  }
  #projects-section .project-content .project-info .project-name {
    font-size: 1.2rem;
  }
  #projects-section .project-content .project-info .project-tech-stack {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #projects-section .project-content .project-info .project-tech-stack .tech-stack-item {
    margin: 0.6rem 1rem;
    width: 3rem;
    height: 3rem;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0.6rem;
    box-shadow: 0rem 0rem 0.9rem 0rem #ddd;
    transition: 0.2s ease-out;
  }
  #projects-section .project-content .project-info .project-tech-stack .tech-stack-item .tech-stack-popup {
    opacity: 0;
    position: relative;
    bottom: 1.5rem;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    font-weight: 600;
    transition: 1.5s ease-out;
  }
  #projects-section .project-content .project-info .project-button-prompts .project-button {
    font-size: 0.7rem;
    padding: 0.3rem 0.6rem;
  }
}

#about-section #about-description {
  text-align: center;
}
#about-section #about-image {
  margin: 3rem 0rem;
  position: relative;
  height: 40rem;
  background-image: url("/public/about_section_image2.jpg");
  background-size: cover;
  background-position: top center;
  border-radius: 0.6rem;
}
#about-section #about-image::after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #183642;
  filter: opacity(70%);
  border-radius: 0.6rem;
  content: "";
}
#about-section #about-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}
#about-section #about-content .about-content-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#about-section #about-content .about-content-column .about-column-symbol {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0.8;
}
#about-section #about-content .about-content-column .about-column-symbol::before, #about-section #about-content .about-content-column .about-column-symbol::after {
  margin: 0rem 0.5rem;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #183642;
}
#about-section #about-content .about-content-column .about-column-symbol svg {
  margin: 0rem 1rem;
  color: #183642;
  font-size: 3rem;
}
#about-section #about-content .about-content-column .about-column-text {
  text-align: justify;
}
@media (max-width: 1536px) {
  #about-section #about-image {
    height: 35rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol::before, #about-section #about-content .about-content-column .about-column-symbol::after {
    margin: 0rem 0.4rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol svg {
    margin: 0rem 0.8rem;
    font-size: 2.5rem;
  }
}
@media (max-width: 1200px) {
  #about-section #about-image {
    height: 30rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol::before, #about-section #about-content .about-content-column .about-column-symbol::after {
    margin: 0rem 0.3rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol svg {
    margin: 0rem 0.6rem;
    font-size: 2rem;
  }
}
@media (max-width: 1024px) {
  #about-section #about-image {
    height: 25rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol::before, #about-section #about-content .about-content-column .about-column-symbol::after {
    margin: 0rem 0.2rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol svg {
    margin: 0rem 0.5rem;
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  #about-section #about-image {
    height: 15rem;
  }
  #about-section #about-content {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol::before, #about-section #about-content .about-content-column .about-column-symbol::after {
    margin: 0rem 0.5rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol svg {
    margin: 0rem 1rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  #about-section #about-image {
    height: 10rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol::before, #about-section #about-content .about-content-column .about-column-symbol::after {
    margin: 0rem 0.6rem;
  }
  #about-section #about-content .about-content-column .about-column-symbol svg {
    margin: 0rem 0.5rem;
    font-size: 1.5rem;
  }
}

#skills-section #skills-description {
  text-align: center;
}
#skills-section #skills-categories {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
#skills-section #skills-categories .skills-category {
  margin: 2rem;
  padding: 2rem;
  border-radius: 5rem;
  background-color: #fff;
  box-shadow: 0rem 0rem 3rem 0rem #ddd;
  border: 0.1rem solid #183642;
  background: linear-gradient(0deg, #ddd 0%, #eee 30%);
}
#skills-section #skills-categories .skills-category h3 {
  text-align: center;
  font-weight: 300;
  font-size: 1.8rem;
}
#skills-section #skills-categories .skills-category .skills-grid {
  padding: 1.8rem 0rem;
  display: grid;
  grid-template: repeat(1, 1fr)/repeat(3, 1fr);
  column-gap: 0.6rem;
  row-gap: 0.6rem;
}
#skills-section #skills-categories .skills-category .skills-grid .skill-cell {
  display: flex;
  justify-content: center;
}
#skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  background-color: transparent;
  border: 1px solid transparent;
  transition: 0.5s;
}
#skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip .skill-icon {
  position: absolute;
  width: inherit;
  height: inherit;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.5s;
}
#skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip .skill-name {
  opacity: 0;
  font-weight: 800;
  text-align: center;
  transition: 0.2s;
}
#skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip:hover {
  border: 1px solid #183642;
  border-radius: 3rem;
  background-color: #183642;
  color: #fff;
}
#skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip:hover .skill-icon {
  opacity: 0;
}
#skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip:hover .skill-name {
  opacity: 1;
}
#skills-section #additional-skills {
  padding-top: 3rem;
  text-align: center;
}
@media (max-width: 480px) {
  #skills-section #skills-categories .skills-category {
    margin: 1rem;
    padding: 1rem;
    border-radius: 3rem;
  }
  #skills-section #skills-categories .skills-category h3 {
    font-size: 1.2rem;
  }
  #skills-section #skills-categories .skills-category .skills-grid {
    padding: 1rem 0rem;
    column-gap: 0.6rem;
    row-gap: 0.6rem;
  }
  #skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
  }
  #skills-section #skills-categories .skills-category .skills-grid .skill-cell .skill-chip .skill-name {
    font-size: 0.5rem;
  }
}