#footer {
    min-height: 1.8rem;
    background: url('/public/bg_bggenerator_com4.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;

    #footer-content {
        margin: auto;
        padding: 0% 10%;
        max-width: $desktop-width;

        #footer-name {
            padding: 3rem 0rem;
            font-family: 'Bungee Shade';
            font-size: 3rem;
            text-align: center;
        }

        #footer-nav {
            margin: auto;
            width: 100%;

            ul {
                width: 100%;
                display: flex;

                li {
                    flex-grow: 1;
                    list-style: none;
                    text-align: center;

                    a {
                        text-decoration: none;
                        color: #fff;
                        transition: 0.2s;
                    }

                    &:hover {
                        cursor: pointer;

                        a {
                            color: $secondary-color;
                        }
                    }
                }
            }
        }

        #footer-social-links {
            width: 100%;

            ul {
                padding: 5rem 0rem;

                li {
                    list-style: none;
                    margin: 2rem 0rem;

                    a {
                        display: flex;
                        align-items: center;
                        font-size: 1.3rem;
                        text-decoration: none;
                        color: #fff;

                        svg {
                            width: 1.6rem;
                            height: auto;
                            margin-right: 0.5rem;
                            transition: 0.3s;
                        }

                        &:hover {
                            color: $secondary-color;

                            svg {
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }

        #footer-attributions {
            text-align: center;
            font-size: 0.8rem;

            a {
                font-weight: 800;
                text-decoration: none;
                color: #fff;
            }
        }

        #footer-bottom {
            margin: auto;
            width: 100%;
            border-top: 1px solid #666;

            span {
                display: block;
                padding: 2rem 0rem;
                text-align: center;
                font-size: 0.7rem;
            }
        }
    }

    @media (max-width: $tv-width) {}

    @media (max-width: $desktop-width) {}

    @media (max-width: $laptop-width) {}

    @media (max-width: $tablet-width) {
        #footer-content {

            #footer-name {
                font-size: 2.4rem;
            }

            #footer-nav {

                ul {

                    li {

                        a {
                            font-size: 0.8rem;
                        }
                    }
                }
            }

            #footer-social-links {

                ul {
                    padding: 3rem 0rem;

                    li {

                        a {

                            svg {}
                        }
                    }
                }
            }

            #footer-attributions {


                a {}
            }

            #footer-bottom {


                span {}
            }
        }
    }

    @media (max-width: $mobile-width) {
        #footer-content {

            #footer-name {
                font-size: 1.9rem;
            }

            #footer-nav {
                width: 100%;

                ul {

                    li {

                        a {
                            font-size: 0.7rem;
                        }
                    }
                }
            }

            #footer-social-links {

                ul {

                    li {

                        a {
                            font-size: 1rem;

                            svg {
                                width: 1.2rem;
                            }
                        }
                    }
                }
            }

            #footer-attributions {


                a {}
            }

            #footer-bottom {


                span {}
            }
        }
    }
}