#hero-section-container {
    background: url('/public/bg_bggenerator_com1.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50rem;
    text-align: center;

    #hero-section-content {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 80%;
        width: 40rem;
        color: $primary-color;

        #greeting {
            font-family: $font-stack;
            font-size: 2.8rem;
            font-weight: 500;
            color: $primary-color;

            #name {
                font-family: 'Bungee Shade';
                font-size: 4rem;
                color: $secondary-color;
                background: linear-gradient(0deg, $secondary-color 25%, $primary-color 60%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        #role {
            font-size: 2.5rem;
            font-weight: 500;
            color: $primary-color;

            #role-title {
                font-size: 3rem;
                font-weight: 900;
                color: $primary-color;

                span {
                    color: $secondary-color;
                    font-weight: 900;
                }
            }
        }

        #intro {
            font-size: 1.5rem;
            font-weight: 500;
            color: $primary-color;
        }

        #call-to-action {
            display: flex;
            justify-content: center;
            padding-top: 3rem;

            #call-to-action-button {
                position: relative;
                padding: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $secondary-color;
                text-decoration: none;
                border: 0.3rem solid $secondary-color;
                border-radius: 0.3rem;
                backdrop-filter: blur(50px);

                .button-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 1rem;
                    border: 0.1rem solid $secondary-color;
                    border-radius: 0.3rem;
                    font-size: 1.8rem;
                    font-weight: 800;
                    text-decoration: none;
                    white-space: nowrap;
                    z-index: 1;
                    transition: 0.5s;
                }

                &::before {
                    position: absolute;
                    content: '';
                    width: 0%;
                    height: 100%;
                    background-color: #fff;
                    transition: 1s;
                }

                &:hover {
                    color: #fff;
                    background-color: $secondary-color;

                    .button-content {
                        border-color: #fff;
                    }

                    &::before {
                        width: 100%;
                        height: 100%;
                        background-color: $secondary-color;
                    }
                }
            }

        }
    }

    #grid {
        display: grid;
        grid-template: repeat(20, 1fr) / repeat(20, 1fr);
        height: 50rem;
        width: 50rem;
        color: $primary-color;

        &:hover {
            cursor: url('/public/paintbrush_color_cursor.png'), pointer;
        }

        .cell {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(128, 128, 128, 0);
            color: rgba(128, 128, 128, 0);
            z-index: 1;
        }

        @for $index from 0 through 19 {
            .cell:nth-child(20n + #{$index + 1}):hover~#top-layer {
                --positionX: #{$index};
            }

            .cell:nth-child(n + #{20 * $index + 1}):nth-child(-n + #{20 * ($index + 1)}):hover~#top-layer {
                --positionY: #{$index};
            }
        }

        #top-layer {
            --positionX: 0;
            --positionY: 0;

            position: absolute;
            width: inherit;
            height: inherit;
            background: url('/public/portrait_painting.png'); // sketch
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;

            #bottom-layer {
                position: absolute;
                background: url('/public/portrait_real.png');
                background-size: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                height: inherit;
                width: inherit;
                mask-image: url('/public/cursor-mask.svg');
                -webkit-mask-image: url('/public/cursor-mask.svg');
                mask-size: 12.5rem;
                mask-repeat: no-repeat;
                mask-position: calc(var(--positionX) * 5.27%) calc(var(--positionY) * 5.27%);
                transition: all 0.25s ease-out;
            }
        }
    }

    @media (max-width: $tv-width) {
        height: 40rem;

        #hero-section-content {
            height: 80%;
            width: 35rem;

            #greeting {
                font-size: 2.9rem;
            }

            #intro {
                font-size: 1.3rem;
            }

            #call-to-action {
                padding: 2rem 0rem;

                #call-to-action-button {

                    .button-content {
                        font-size: 1.6rem;
                    }
                }
            }
        }

        #grid {
            height: 40rem;
            width: 40rem;
        }
    }

    @media (max-width: $desktop-width) {
        height: auto;
        flex-direction: column;

        #hero-section-content {
            height: 40rem;
            width: 40rem;
            text-align: center;

            #greeting {}

            #role {}

            #intro {}

            #call-to-action {

                #call-to-action-button {
                    .button-content {}
                }
            }
        }
    }

    @media (max-width: $laptop-width) {
        #hero-section-content {

            #greeting {}

            #intro {}

            #call-to-action {

                #call-to-action-button {

                    .button-content {}
                }
            }
        }

        #grid {

            .cell {}

            #top-layer {

                #bottom-layer {}
            }
        }
    }

    @media (max-width: $tablet-width) {

        #hero-section-content {
            height: 40rem;
            width: 100%;

            #greeting {
                font-size: 2rem;

                #name {
                    font-size: 3rem;
                }
            }

            #role {
                font-size: 2rem;

                #role-title {
                    font-size: 2.4rem;
                }
            }

            #intro {
                font-size: 1.2rem;
            }

            #call-to-action {

                #call-to-action-button {
                    .button-content {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        #grid {
            width: 28rem;
            height: 28rem;

            #top-layer {

                #bottom-layer {
                    mask-size: 8.5rem;
                }
            }
        }
    }

    @media (max-width: $mobile-width) {

        #hero-section-content {
            height: 35rem;

            #greeting {
                font-size: 1.5rem;

                #name {
                    font-size: 2.2rem;
                }
            }

            #role {
                font-size: 1.5rem;

                #role-title {
                    font-size: 1.9rem;
                }
            }

            #intro {
                font-size: 1rem;
            }

            #call-to-action {

                #call-to-action-button {
                    padding: 0.4rem;

                    .button-content {
                        padding: 0.8rem;
                        font-size: 1.2rem;
                    }
                }
            }
        }

        #grid {
            width: 20rem;
            height: 20rem;

            #top-layer {

                #bottom-layer {
                    mask-size: 6rem;
                }
            }
        }
    }
}